'use strict';

(function() {

  class GlobalCtrl {
    constructor($state, $rootScope) {
      this.$state = $state;
      this.noExtraFeatures = false;
      this.sideNavOpen = false;
      this.topNavOpen = false;
      this.footerOpen = false;
      this.sharedLevel = localStorage.getItem('sharedLevel');
      this.noExtraFeaturesAllowedRoutes = ['login', 'forgotPassword', 'resetPassword','printReport`'];
      this.sideNavAllowedRoutes = ['main', 'turbine', 'blade', 'finding', 'cart', 'wopackage', 'site', 'repair', 'printFindings', 'admin', 'report-config', 'activity-log', 'clients', 'client'];
      this.topNavAllowedRoutes = ['main', 'turbine', 'blade', 'finding', 'cart', 'wopackage', 'site', 'repair', 'printFindings', 'admin', 'report-config', 'activity-log', 'clients', 'client'];
      this.footerNavAllowedRoutes = ['main', 'turbine', 'blade', 'finding', 'cart', 'wopackage', 'site', 'repair', 'printFindings', 'admin', 'report-config', 'activity-log', 'clients', 'client'];
      $rootScope.$on('$stateChangeStart', (event, next) => {
        this.sideNavOpen = this.sideNavAllowedRoutes.indexOf(next.name) > -1;
        this.topNavOpen = this.topNavAllowedRoutes.indexOf(next.name) > -1;
        this.footerOpen = this.footerNavAllowedRoutes.indexOf(next.name) > -1;
        this.noExtraFeatures = this.noExtraFeaturesAllowedRoutes.indexOf(next.name) > -1;
        this.sharedLevel = localStorage.getItem('sharedLevel');
      });

      this.mobileHeaderNavOptions = {
        hideOnClickOutside: true,
        menuActiveClass: 'header-active',
        menuOpener: '.header-opener',
        menuDrop: '#header'
      };
      this.mobileMenuNavOptions = {
        hideOnClickOutside: true,
        menuActiveClass: 'menu-active',
        menuOpener: '.menu-opener',
        menuDrop: '.right-info'
      };
      this.mobileListingNavOptions = {
        hideOnClickOutside: true,
        menuActiveClass: 'listing-active',
        menuOpener: '.btn-listing',
        menuDrop: '.listing-block'
      };
    }
  }

  angular.module('windmanagerApp')
    .controller('GlobalCtrl', GlobalCtrl);
})();
